.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #21bf96 !important;
}

.ant-menu-horizontal > .ant-menu-item-selected a,
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #21bf96 !important;
}

.ant-modal-content {
  overflow: auto;
  border-radius: 1rem;
}

.ant-layout-header { /*resto do header*/
  color:#ffffff;
}

.ant-menu-horizontal > .ant-menu-item a { /*ALELUIA*/
  color: #ffffff;
}

.ant-btn  { /*ALELUIA*/
  background-color: #ffffff;
  color: #21bf96;
  border: 0;
  
}

.ant-btn:hover {
  background-color: #21bf96;
  color:white;
  border:0;
}

.yoyo:empty {
  background-color: #21bf96;
  color:white;
  border:0;
}

.titulo{
  text-align: center;
}

.ant-card-bordered > .ant-card-cover {
  margin-top: 20px;
  margin-right: 10px;
  margin-left: 79px;
}

.ant-card-meta-title{
  text-align: center;
}

.ant-card.ant-card-bordered.ant-card-hoverable{
  border-radius: 25px;
}

.ant-card-actions{
  border-radius: 25px;
}